<template>
	<edit-template @confirm="save('formData')" @cancel="open" :cancelRemind="false" confirmText="保存" cancelText="取消">

		<!-- 大表单 -->
		<div class="form-body">
			<el-form ref="formData" :model="form" label-width="150rem" style="width: 500rem" :rules="rules">
				<el-form-item label="手机号" prop="mobile">
					<el-input v-model.number="form.mobile" @change="changeInput" maxlength="11" placeholder="请输入您的手机号"></el-input>
				</el-form-item>
				<el-form-item label="登录密码">
					<el-input v-model="form.password" placeholder="********" show-password=""></el-input>
				</el-form-item>
				<el-form-item label="姓名" prop="name">
					<el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
				</el-form-item>
				<el-form-item label="性别" prop="gender">
					<el-select :popper-append-to-body="false" v-model="form.gender" placeholder="请选择您的性别">
						<el-option label="男" :value="1"></el-option>
						<el-option label="女" :value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="角色" prop="roles">
					<el-select :popper-append-to-body="false" v-model="form.roles" placeholder="请选择您的角色">
						<el-option v-for="i in rolesArr" :label="i.name" :value="i.id" :key="i.id"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<!-- 面板展示 -->
			<el-descriptions class="margin-top" :column="3" border="">
				<el-descriptions-item style="width: 150rem">
					<template slot="label">userid:</template>
					{{ form.userid == null ? '' : form.userid }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">dd_unionid:</template>
					{{ form.dd_unionid == null ? '' : form.dd_unionid }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">wx_unionid:</template>
					{{ form.wx_unionid == null ? '' : form.wx_unionid }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">创建人:</template>
					{{ form.creator == null ? '' : form.creator }}
				</el-descriptions-item>
				<el-descriptions-item>
					<template slot="label">创建时间:</template>
					{{ form.created_at == null ? '' : form.created_at }}
				</el-descriptions-item>
			</el-descriptions>
		</div>

		<!-- 底部按钮 -->


</edit-template>
</template>

<script>
import { editUser, putEditUser, siteRole } from '@/api/account-management'

export default {
	_config:{"route":{"path":"edit","meta":{"title":"编辑"}}},
  data() {
		return {
			rolesArr: [],
			checkData: [],
			form: {
				mobile: '', // 手机号
				password: '', // 密码
				name: '', // 姓名
				gender: '', // 性别
				roles: '', // 角色
        role_type: 3
			},
			rules: {
				name: [
					{ required: true, message: '请输入您的姓名', trigger: 'blur' },
					{ min: 1, max: 10, message: '不超过十个字符', trigger: 'blur' }
				],
				mobile: [{ required: true, message: '请输入您的手机号', trigger: 'blur' }],
				password: [{ required: true, message: '请输入您的密码', trigger: 'blur' }],
				gender: [{ required: true, message: '请选择你的性别', trigger: 'change' }],
				roles: [{ required: true, message: '请选择你的角色', trigger: 'change' }]
			}
		}
	},

	methods: {
		save(formData) {
			this.rules.password[0].required = false
			this.$refs[formData].validate(valid => {
				if (valid) {
					let data = {}
					data.mobile = this.form.mobile // 手机号
					data.password = this.form.password // 密码
					data.name = this.form.name // 姓名
					data.gender = this.form.gender // 性别
					data.roles = this.form.roles // 角色
					putEditUser(this.$route.query.id, data).then(res => {
						if (res.data.error.errorCode != 0) {
							this.$notify.error({
								title: '保存出错',
								message: res.data.error.errorUserMsg
							})
						} else {
							this.$notify({
								title: '保存成功',
								message: res.data.error.errorUserMsg,
								type: 'success'
							})
							this.$router.back()
						}
					})
				} else {
					return false
				}
			})
		},
		open() {
			this.$confirm('是否取消编辑', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$router.back()
				})
				.catch(() => {})
		},
		changeInput(val) {
			var myreg = /^1[3|4|5|6|7|8|9][0-9]{9}$/
			if (!myreg.test(val)) {
				this.$message.error('请输入正确手机号格式')
			}
		}
	},
	mounted() {},
	created() {
		editUser(this.$route.query.id).then(res => {
			this.form = res.data.data
			this.form.roles = JSON.parse(this.form.roles)[0];
		})
		siteRole({ type: 3 }).then(res => {
			this.rolesArr = res.data.data
		})
	}
}
</script>

<style lang="scss" scoped>
</style>
