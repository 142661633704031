/**
 * 子管理员账号管理模块
 */

import request from '@/utils/request'

//get 请求
/* 获取角色列表*/
export const getRoleList = (page = 1) => {
    return request({
        method: 'get',
        url: '/user?roles=3' + '&page=' + page,
    })
}

// post校区列表接口
export const campusList = () => {
    return request({
        method: 'get',
        url: '/setup/campus',
    })
}

// 新增接口
export const addUser = (data) => {
    return request({
        method: 'post',
        url: '/user',
        data
    })
}

// 修改获取数据
export const editUser = (data) => {
    return request({
        method: 'get',
        url: '/user/' + data,
    })
}

// 修改提交数据
export const putEditUser = (id, data) => {
    return request({
        method: 'put',
        url: '/user/' + id,
        data
    })
}

// 账号启用禁用
export const putUser = (id, data) => {
    return request({
        method: 'put',
        url: 'user/' + id,
        data
    })
}

// 教职工列表请求
export const getUser = (page = 1, mobile, name) => {
    return request({
        method: 'get',
        url: 'user?roles=2' + '&page=' + page + '&mobile=' + mobile + '&name=' + name,
    })
}

// 教职工新增
export const newUser = (data) => {
    return request({
        method: 'post',
        url: '/user',
        data
    })
}

// 教职工角色列表获取
export const optionList = () => {
    return request({
        method: 'get',
        url: '/role/list',
    })
}

// 教职工角色列表获取
export const editor = (id, data) => {
    return request({
        method: 'put',
        url: '/user/' + id,
        data
    })
}

// 搜索
export const search = (data) => {
    return request({
        method: 'POST',
        url: '/user/search',
        data
    })
}

// 学生账号管理列表数据获取
export const studentsList = (page, name, students_no, grade, administrativeClass) => {
    return request({
        method: 'get',
        url: 'user?roles=1' + '&page=' + page + '&name=' + name + '&students_no=' + students_no + '&grade=' + grade + '&administrativeClass=' + administrativeClass,
    })
}

// 学生编辑信息获取
export const obtain = (id) => {
    return request({
        method: 'get',
        url: '/user/' + id,
    })
}

// 学生编辑信息提交
export const submit = (id, data) => {
    return request({
        method: 'put',
        url: '/user/' + id,
        data
    })
}

// 行政班班级获取
export const administrative = () => {
    return request({
        method: 'get',
        url: '/site/class-data',
    })
}

// 行政班类型获取
export const setupadministrative = (page) => {
    return request({
        method: 'get',
        url: 'setup/administrative?page=' + page,
    })
}

// 科目列表
export const subject = (page = 1) => {
    return request({
        method: 'get',
        url: '/setup/subject' + '?page=' + page,
    })
}

// 删除科目
export const subjectDel = (id) => {
    return request({
        method: 'get',
        url: '/setup/subject-del?id=' + id,
    })
}

// 新增科目
export const subjectAdd = (data) => {
    return request({
        method: 'post',
        url: '/setup/subject-add',
        data
    })
}

// 新增行政班类型
export const administrativeAdd = (data) => {
    return request({
        method: 'post',
        url: 'setup/administrative-add',
        data
    })
}

// 编辑科目
export const subjectEdit = (data) => {
    return request({
        method: 'post',
        url: 'setup/subject-edit',
        data
    })
}

// 编辑行政班
export const administrativeEdit = (data) => {
    return request({
        method: 'post',
        url: 'setup/administrative-edit',
        data
    })
}


// 科目详情
export const subjectDetails = (id) => {
    return request({
        method: 'get',
        url: '/setup/subject-details?id=' + id,
    })
}

// 行政班类型详情
export const administrativeDetails = (id) => {
    return request({
        method: 'get',
        url: '/setup/administrative-details?id=' + id,
    })
}

// 教学班列表
export const teaching = (page = 1, grade_id, class_name) => {
    return request({
        method: 'get',
        url: '/teaching' + '?page=' + page + '&grade_id=' + grade_id + '&class_name=' + class_name,
    })
}

// 教学班新增
export const teachingAdd = (data) => {
    return request({
        method: 'post',
        url: 'teaching/add',
        data
    })
}

// 教学班新增
export const teachingEdit = (data) => {
    return request({
        method: 'post',
        url: 'teaching/edit',
        data
    })
}

// 教学班编辑详情
export const teachingDetails = (id) => {
    return request({
        method: 'get',
        url: '/teaching/details?id=' + id,
    })
}

// 公共接口-获取校区
export const school = () => {
    return request({
        method: 'get',
        url: '/site/school',
    })
}

// 公共接口-获取年级
export const getGrade = () => {
    return request({
        method: 'get',
        url: '/site/grades',
    })
}

// 公共接口-获取科目
export const getSubject = (id) => {
    return request({
        method: 'get',
        url: '/site/subject?pid=' + id,
    })
}

// 公共接口-按类型获取角色类型
export const siteRole = (data) => {
    return request({
        method: 'post',
        url: '/site/role',
        data
    })
}
// 获取科目一级分类
export const fistSubject = () => {
    return request({
        method: 'get',
        url: 'site/fist-subject',
    })
}
// 及联选择科目获取
export const subjectArr = () => {
    return request({
        method: 'get',
        url: 'site/subject',
    })
}

// 获取年级
export const grades = () => {
    return request({
        method: 'get',
        url: 'site/grades',
    })
}

// 获取班级
export const classData = () => {
    return request({
        method: 'get',
        url: '/site/class-data',
    })
}